import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'

const Wrapper = styled.section`
  display: flex;
  width: 100%;
  text-align: center;
  margin: 60px 0 60px;
  justify-content: center;
  align-items: center;

  h3 {
    font-size: 2.5em;
    margin: 0 0 20px;
    white-space: pre-wrap;
  }

  p {
    white-space: pre-wrap;
    font-weight: 600;
    opacity: 0.6;
  }

  .p-h3-line1 {
    width: 48px;
    height: 25px;
    margin: 20px 30px;
    position: relative;
    transform: scale(-1, 1) rotate(-45deg);

    &:before,
    &:after {
      display: block;
      content: '';
      position: absolute;
      width: 4px;
      height: 4px;
      border-radius: 2px;
      background: #3ec7b3;
      top: 0;
    }

    &:after {
      left: 8px;
    }

    span {
      display: block;
      background: #3ec7b3;
      width: 32px;
      margin-left: auto;
      height: 4px;
      border-radius: 2px;
    }
  }

  .p-h3-line2 {
    width: 48px;
    height: 25px;
    margin: 20px 30px;
    position: relative;
    transform: rotate(-45deg);

    &:before,
    &:after {
      display: block;
      content: '';
      position: absolute;
      width: 4px;
      height: 4px;
      border-radius: 2px;
      background: #3ec7b3;
      top: 0;
    }

    &:after {
      left: 8px;
    }

    span {
      display: block;
      background: #3ec7b3;
      width: 32px;
      margin-left: auto;
      height: 4px;
      border-radius: 2px;
    }
  }

  ${BreakPoints.medium} {
    h3 {
      font-size: 2em;
    }

    margin: 0 auto 30px;
    word-break: break-word;

    p {
      width: 70%;
      margin: 0 auto;
    }
  }

  ${BreakPoints.smallest} {
    p {
      width: 260px;
    }
  }
`

type Props = {
  /** クラス名 */
  className?: string
  /** タイトル */
  label?: string
}

/**
 * 代理申請機能紹介ページ-斜線付き見出し
 * @constructor
 */

const AgentModeFunctionH3 = (props: Props): JSX.Element => {
  const { className = '' } = props

  return (
    <Wrapper className={className}>
      <div className="p-h3-line1">
        <span />
      </div>
      <h3>{props.label}</h3>
      <div className="p-h3-line2">
        <span />
      </div>
    </Wrapper>
  )
}

export default AgentModeFunctionH3
