import styled from '@emotion/styled'
import { graphql, PageProps } from 'gatsby'
import React from 'react'
import SEO from '../../components/atoms/seo'
import AgentModeFunction01 from '../../components/organisms/agentModeFunction01'
import AgentModeFunction02 from '../../components/organisms/agentModeFunction02'
import AgentModeFunction03 from '../../components/organisms/agentModeFunction03'
import OfficialLayout from '../../components/organisms/officialLayout'
import OfficialLowHeader from '../../components/organisms/officialLowHeader'

const Wrapper = styled.main``

type IProps = PageProps<GatsbyTypes.IndexPageQuery>

/**
 * 機能紹介ページ[代理申請機能]
 * @constructor
 */
const AgentMode = ({ location }: IProps) => (
  <OfficialLayout location={location} className="p-page-function">
    <SEO title="代理申請機能の紹介" />
    <Wrapper>
      <OfficialLowHeader
        label="代理申請機能"
        description="対象者本人に代わって代理でキンクラを操作"
      />
      <AgentModeFunction01 />
      <AgentModeFunction02 />
      <AgentModeFunction03 />
    </Wrapper>
  </OfficialLayout>
)

export default AgentMode

export const pageQuery = graphql`
  query AgentMode {
    site {
      siteMetadata {
        title
      }
    }
  }
`
