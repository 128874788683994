import styled from '@emotion/styled'
import { navigate } from 'gatsby-link'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import OfficialCommonBtn from '../atoms/officialCommonBtn'
import OfficialCommonH3Set from '../molecules/officialCommonH3Set'
import OfficialFaqItems from '../molecules/officialFaqItems'
import GalleryBtn from '../atoms/galleryBtn'
import GalleryContent from '../atoms/galleryContent'

const Wrapper = styled.section`
  width: 100%;
  text-align: center;
  padding: 80px 20px;

  .p-section-faq {
    text-align: left;
    max-width: 1080px;
    margin: 0 auto;
  }

  .modal-btn {
    position: relative;
    margin: 5px 0 0 20px;

    &:before {
      display: block;
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      top: 7px;
      left: -15px;
      border-left: 8px solid #3ec7b3;
      border-top: 3px solid transparent;
      border-bottom: 3px solid transparent;
    }

    a {
      text-decoration: underline solid rgba(255, 255, 255, 0);
      transition: 0.3s all;
      display: inline-block;
      width: unset;
      height: unset;
      padding: 0;
      opacity: 1;

      span {
        font-size: 15px;
        font-weight: normal;
      }

      &:before {
        display: none;
      }

      &:hover {
        opacity: 0.6;
        text-decoration: underline solid #3abd9e !important;

        &:before {
          display: none;
        }
      }
    }

    img {
      display: none !important;
    }
  }

  ${BreakPoints.xxLarge} {
    .p-section-inner {
      .p-section-function {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .function-items {
          float: unset;

          .description {
          }
        }
      }
    }
  }

  ${BreakPoints.medium} {
    .p-section-inner {
      padding: 60px 0 0;

      .p-section-function {
        .function-items {
          width: calc(100vw - 80px);
          height: auto;

          .description {
          }
        }
      }
    }
  }
`

/**
 * 代理申請機能紹介ページ-代理機能申請でできること
 * @constructor
 */
const AgentModeFunction03 = () => (
  <>
    <Wrapper>
      <OfficialCommonH3Set
        label="代理申請機能ってどんな機能？"
        className="anim-fade-up"
      />
      <div className="p-section-faq anim-fade-up">
        <OfficialFaqItems
          question="自分のデータと誤って操作する心配はありませんか"
          answer={
            '代理申請者として操作しているときの画面上の見た目を大きく変更しているので、\nご自身のデータ操作と誤って操作する心配はございません。'
          }
          className="is-show"
          idName="matter-01"
        >
          <GalleryBtn
            label="画面イメージを確認する"
            idName="#modal-01"
            className="modal-btn"
            fullImg="thum17"
          />
        </OfficialFaqItems>
        <OfficialFaqItems
          question="代理申請を行うにはどうすれば良いですか"
          answer={
            '一定の権限(※)以上のユーザでログインし、対象者の社員情報詳細画面から代理申請モードに切り替える事が可能です。\n※承認・労務担当者 / システム管理者 / アカウントオーナー権限を持つユーザのみ代理申請をご利用いただけます。'
          }
          idName="matter-02"
        />
        <OfficialFaqItems
          question="代理申請からの申請か本人からの申請かの区別はできますか"
          answer={
            '代理申請により提出・修正された申請には、申請者欄に「代理：代理申請者名」が表示されるため、\n誰のどの申請を誰が代理で申請しているかがわかります。'
          }
          idName="matter-03"
        />
      </div>
      <div className="common-button">
        <OfficialCommonBtn
          size="mega"
          className="anim-fade-up"
          onClick={() => {
            navigate('/function')
          }}
        >
          その他の機能を見る
        </OfficialCommonBtn>
      </div>
    </Wrapper>
    {/** GalleryBtnに対応したGalleryContentを配置する */}
    <GalleryContent label="代理申請" idName="modal-01" fullImg="thum17" />
  </>
)

export default AgentModeFunction03
