import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import OfficialCommonH3Set from '../molecules/officialCommonH3Set'

const Wrapper = styled.section`
  width: 100%;
  font-weight: bold;

  .p-section-inner {
    max-width: 1080px;
    padding: 80px 0 50px;
    margin: 0 auto;
    text-align: center;
    position: relative;
    overflow: hidden;

    .p-section-function {
      padding: 60px 0 40px 0;
      border-radius: 10px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      position: relative;
    }

    .bg-agent-function {
      width: 100%;
      background-image: url(/images/official/function/bgAgentMode01.png);
      background-size: cover;
      overflow: hidden;
      z-index: -1;
    }

    .bg-agent-function:before {
      content: '';
      background: inherit;
      background-color: rgba(255, 255, 255, 0.3);
      background-blend-mode: lighten;
      filter: blur(5px);
      position: absolute;
      top: -20px;
      left: -20px;
      right: -20px;
      bottom: -20px;
      z-index: -1;
    }

    .function-chart {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-around;
      filter: drop-shadow(0px 3px 6px #666);

      p {
        color: #2074d6;
        font-size: 20px;
        margin-top: 10px;
      }
    }

    .function-chart-box {
      position: relative;
      height: 350px;
      width: 200px;
    }

    .function-chart-line01 {
      content: '';
      position: absolute;
      height: 210px;
      width: 10px;
      background-color: #fff;
      transform: rotate(-60deg);
      top: 152px;
      left: 220px;
    }

    .function-chart-line02 {
      content: '';
      position: absolute;
      height: 100px;
      width: 10px;
      background-color: #fff;
      transform: rotate(340deg);
      top: 160px;
      left: 105px;
    }

    .function-chart-line03 {
      content: '';
      position: absolute;
      height: 100px;
      width: 10px;
      background-color: #fff;
      transform: rotate(20deg);
      top: 160px;
      left: 80px;
    }

    .function-chart-line04 {
      content: '';
      position: absolute;
      height: 210px;
      width: 10px;
      background-color: #fff;
      transform: rotate(60deg);
      top: 152px;
      left: -30px;
    }

    .function-chart-img01 {
      position: relative;
      border-radius: 50%;
      height: 200px;
      width: 200px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      top: 30px;

      img {
        height: 80px;
      }
    }

    .function-chart-img02 {
      position: relative;
      border-radius: 50%;
      height: 200px;
      width: 200px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        height: 80px;
      }
    }

    .function-chart-img03 {
      position: relative;
      border-radius: 50%;
      height: 200px;
      width: 200px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        height: 80px;
      }
    }

    .function-chart-img04 {
      position: relative;
      border-radius: 50%;
      height: 200px;
      width: 200px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      top: 30px;

      img {
        height: 80px;
      }
    }
  }

  .p-agent-function {
    background-color: #fff;
    width: 80%;
    border-radius: 10px;
    box-shadow: 0 3px 6px #666;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: -20px auto 0;
  }

  .agent-img {
    position: relative;
    z-index: 10;
    margin-top: 40px;
  }

  .agent-img img {
    height: 150px;
    position: absolute;
    top: -113px;
    left: 10%;
    z-index: -1;
  }

  .p-agent-img {
    color: #fff;
    width: fit-content;
    padding: 7px 63px;
    background-color: #002faf;
  }

  .agent-function-text {
    margin: 30px 0 40px 0;
    font-size: 20px;
  }

  ${BreakPoints.xxLarge} {
    .p-section-inner {
      .p-section-function {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .function-items {
          float: unset;

          .description {
          }
        }
      }
    }
  }

  ${BreakPoints.xLarge} {
    .p-section-inner {
      .function-chart {
        flex-wrap: nowrap;
      }

      .function-chart-box {
        height: 280px;
      }

      .function-chart-line01,
      .function-chart-line02,
      .function-chart-line03,
      .function-chart-line04 {
        display: none;
      }
    }
  }

  ${BreakPoints.medium} {
    .p-section-inner {
      padding: 60px 0 0;

      .p-section-function {
        padding: 0;

        .function-items {
          width: calc(100vw - 80px);
          height: auto;

          .description {
          }
        }
      }

      .function-chart {
        display: block;
        text-align: center;
      }

      .function-chart-box {
        height: auto;
        width: auto;
        margin: 0 15px 25px;
        display: inline-block;
      }

      .function-chart-img01,
      .function-chart-img02,
      .function-chart-img03,
      .function-chart-img04 {
        width: 160px;
        height: 160px;
      }

      .function-chart-img01,
      .function-chart-img02,
      .function-chart-img03,
      .function-chart-img04 {
        top: unset;
      }

      .p-agent-function {
        margin-top: 50px;
        margin-bottom: 60px;
        width: 90%;
      }
    }
  }
`

/**
 * 代理申請機能紹介ページ-代理機能申請では
 * @constructor
 */
const AgentModeFunction01 = () => (
  <Wrapper>
    <div className="p-section-inner">
      <OfficialCommonH3Set label="代理申請機能では" className="fade-in-auto" />
      <div className="p-section-function fade-in-auto">
        <div className="bg-agent-function" />
        <div className="function-chart">
          <div className="function-chart-box">
            <div className="function-chart-line01" />
            <div className="function-chart-img01">
              <img
                src="/images/official/function/imgFunction01.png"
                alt="キンクラの打刻"
              />
              <p>打刻</p>
            </div>
          </div>
          <div className="function-chart-box">
            <div className="function-chart-line02" />
            <div className="function-chart-img02">
              <img
                src="/images/official/top/imgFunction02.png"
                alt="キンクラの各種申請"
              />
              <p>各種申請</p>
            </div>
          </div>
          <div className="function-chart-box">
            <div className="function-chart-line03" />
            <div className="function-chart-img03">
              <img
                src="/images/official/function/imgFunction03.png"
                alt="キンクラのシフト管理"
              />
              <p>シフト表提出</p>
            </div>
          </div>
          <div className="function-chart-box">
            <div className="function-chart-line04" />
            <div className="function-chart-img04">
              <img
                src="/images/official/function/imgFunction10.png"
                alt="キンクラの勤務表管理"
              />
              <p>勤務表提出</p>
            </div>
          </div>
        </div>
        <div className="p-agent-function">
          <div className="agent-img">
            <img
              src="/images/official/function/agentMode01.png"
              alt="代理申請者"
            />
            <div className="p-agent-img">
              <p>代理申請者</p>
            </div>
          </div>
          <div className="agent-function-text">
            <p>
              代理申請では、対象者本人に代わって
              <br />
              各種申請や勤務表提出を行うことができます。
            </p>
          </div>
        </div>
      </div>
    </div>
  </Wrapper>
)

export default AgentModeFunction01
